import React from "react";

function Terms() {
  return (
    <div className="container mx-auto p-4 w-full max-w-md sm:max-w-2xl lg:max-w-3xl">
      <h1 className="text-2xl font-bold mb-4">Terms</h1>
      <p>
        <strong>Effective Date:</strong> December 11th, 2024
      </p>
      <br />
      <h2 className="font-bold">1. Introduction</h2>
      <p>
        Welcome to Pixel Combine ("we," "us" "our"). These Terms of Use
        ("Terms") are a binding agreement between you ("user," "you," or "your")
        and Pixel Combine, governing your access to and use of our applications,
        websites, and services (collectively, the "Service"). By accessing or
        using the Service, you agree to be bound by these Terms. If you do not
        agree, please do not use the Service.
      </p>
      <br />
      <h2 className="font-bold">2. Eligibity</h2>
      <ul>
        <li>
          <strong>2.1.</strong> You must be at least 13 years old to use the
          Service. If you are under 18, you must have the consent of a parent or
          legal guardian.
        </li>
        <li>
          <strong>2.2.</strong> By using the Service, you represent and warrant
          that you comply with all applicable laws and regulations.
        </li>
      </ul>
      <br />
      <h2 className="font-bold">3. License to Use the Service</h2>
      <ul>
        <li>
          <strong>3.1.</strong> Subject to these Terms, we grant you a limited,
          non-exclusive, non-transferable, and revocable license to access and
          use the Service for your personal, non-commercial use.
        </li>
        <li>
          <strong>3.2.</strong> You agree not to:
          <ul>
            <li>
              - Copy, modify, or distribute any part of the Service without our
              prior written permission.
            </li>
            <li>
              - Reverse-engineer, decompile, or attempt to extract the source
              code of the Service.
            </li>
            <li>
              - Use the Service for illegal, harmful, or unauthorized purposes.
            </li>
          </ul>
        </li>
      </ul>
      <br />
      <h2 className="font-bold">4. Account Responsibilities</h2>
      <ul>
        <li>
          <strong>4.1.</strong> To use certain features of the Service, you may
          be required to create an account. You agree to:
          <ul>
            <li>
              - Provide accurate, current, and complete information during
              registration.
            </li>
            <li>
              - Maintain the security of your account credentials and promptly
              notify us of any unauthorized use.
            </li>
          </ul>
        </li>
        <li>
          <strong>To Improve User Experience:</strong> We may use your data to
          enhance features, fix bugs, or optimize performance.
        </li>
        <li>
          <strong>Compliance and Legal Obligations:</strong> Your data may be
          processed to comply with legal obligations or requests from law
          enforcement.
        </li>
        <li>
          <strong>4.2.</strong> You are solely responsible for all activities
          conducted through your account. Notify us immediately of any
          unauthorized use.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">5. Code of Conduct</h2>
      <ul>
        <li>
          <strong>5.1.</strong> You agree to use the Service respectfully and
          not to:
          <ul>
            <li>- Harass, threaten, or harm other users.</li>
            <li>
              - Upload or share content that is offensive, illegal, or violates
              intellectual property rights.
            </li>
            <li>- Exploit, cheat, or disrupt the Service's operation.</li>
          </ul>
        </li>
        <li>
          <strong>5.2.</strong> We reserve the right to suspend or terminate
          your account for violations of this Code of Conduct.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">6. Intellectual Property</h2>
      <ul>
        <li>
          <strong>6.1.</strong> All intellectual property rights in the Service,
          including but not limited to software, graphics, logos, and
          trademarks, are owned by Pixel Combine or its licensors.
        </li>
        <li>
          <strong>6.2.</strong> You may not use our intellectual property
          without prior written permission.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">7. User Content</h2>
      <ul>
        <li>
          <strong>7.1.</strong> By uploading or sharing content through the
          Service, you grant us a non-exclusive, royalty-free, worldwide license
          to use, display, and distribute your content for operating and
          promoting the Service.
        </li>
        <li>
          <strong>7.2.</strong> You represent and warrant that you own or have
          the necessary rights to all content you submit and that it does not
          violate any third-party rights or laws.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">8. Disclaimer of Warranties</h2>
      <ul>
        <li>
          <strong>8.1.</strong> THE SERVICE IS PROVIDED “AS IS” AND “AS
          AVAILABLE.” WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
          BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        </li>
        <li>
          <strong>8.2.</strong> We do not guarantee that the Service will be
          uninterrupted, error-free, or secure.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">9. Limitation of Liability</h2>
      <ul>
        <li>
          <strong>9.1.</strong> TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ARE
          NOT LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES
          ARISING FROM YOUR USE OF THE SERVICE.
        </li>
        <li>
          <strong>9.2.</strong> OUR TOTAL LIABILITY TO YOU FOR ANY CLAIMS
          RELATING TO THE SERVICE IS LIMITED TO THE AMOUNT YOU PAID US IN THE
          LAST 12 MONTHS (IF ANY).
        </li>
      </ul>

      <br />
      <h2 className="font-bold">10. Termination</h2>
      <ul>
        <li>
          <strong>10.1.</strong> We may suspend or terminate your access to the
          Service at any time, for any reason, without notice.
        </li>
        <li>
          <strong>10.2.</strong> Upon termination, these Terms will remain in
          effect regarding any rights and obligations accrued before
          termination.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">11. Changes to the Terms</h2>
      <ul>
        <li>
          <strong>11.1.</strong> We may update these Terms from time to time. If
          changes are material, we will notify you via the Service or other
          means.
        </li>
        <li>
          <strong>11.2.</strong> Your continued use of the Service after the
          effective date of updated Terms constitutes your acceptance of the
          changes.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">12. Governing Law and Dispute Resolution</h2>
      <ul>
        <li>
          <strong>12.1.</strong> These Terms are governed by the laws of the
          Republic of Korea, without regard to its conflict of law principles.
        </li>
        <li>
          <strong>12.2.</strong> Any disputes will be resolved exclusively in
          the courts of the Republic of Korea.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">13. Contact Information</h2>
      <p>
        If you have any questions about these Terms, please contact us at:
        contact@pixelcombine.com
      </p>
    </div>
  );
}

export default Terms;
